import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  REQUEST,
  REQUEST_CONTEXT,
  resource,
} from '@angular/core';
import { MatchdayService } from './matchday.service';
import { RequestContext } from '../../server';
import { Matchday } from './matchday.model';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit {
  matchdayService = inject(MatchdayService);
  req = inject<RequestContext>(REQUEST_CONTEXT);
  current!: Promise<Response>;

  ngOnInit(): void {
    this.current = this.req.env.API.fetch(
      new URL('https://fantamanajer.it/api/matchdays/current')
    );
    this.req.ctx.waitUntil(this.current);
  }
}
