import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { firstValueFrom, map, Observable } from 'rxjs';
import { Matchday } from './matchday.model';
import { noAuthIt, noHeadersIt } from '../interceptors';

const url = 'matchdays';
const routes = {
  current: `/${url}/current`,
};

@Injectable({ providedIn: 'root' })
export class MatchdayService {
  readonly #http = inject(HttpClient);

  public getCurrentMatchday(): Promise<Matchday> {
    class HackyHttpHeaders extends HttpHeaders {
      public override has(name: string): boolean {
        // Pretend the `Accept` header is set, so `HttpClient` will not try to set the default value.
        return name.toLowerCase() === 'accept' ? true : super.has(name);
      }
    }

    return firstValueFrom(
      this.#http.get<Matchday>(routes.current, {
        context: noHeadersIt(noAuthIt()),
        withCredentials: false,
        headers: new HackyHttpHeaders(),
      })
    );
  }
}
